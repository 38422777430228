import React, {useState, useEffect} from "react";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import TableDataCom from "../components/TableDataCom";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import {Switch, FormControlLabel, Avatar, Chip} from "@material-ui/core";
import _ from "lodash";
import ImagePreview from "../components/ImagePreview";
import DefaultUserImage from "../images/userDefaultImage.jpg"
import {$user} from "../services/UserFactory";
import {CHANGE_PROFESSIONAL_STATUS} from "../constant";
const ProfessionalUser = () => {
    const [state, setState] = useState({
        onSave: null
    });
    const [professionals, setProfessionals] = useState([]);
    const [total, setTotal] = useState(0);
    const [professionalImage, setProfessionalImage] = useState(null);

    const getProfessionalUsers = async ({page, limit, filters, search}) => {
        const {data: {professionals, total}} = await crud.get("retrieve/web/professional-users", {
            page,
            limit,
            search,
            ...filters
        });
        setProfessionals(professionals);
        setTotal(total);
    }

    useEffect(() => {
        getProfessionalUsers();
    }, []);


    const tableHeaders = [
        "Profile Photo",
        "Customer Name",
        "Mobile",
        "Email",
        "Category",
        "Create At",
        "Status",
    ];
    return (
        <>
            <Appbar/>
            <Drawer/>

            <TableDataCom from_date to_date search state={professionals}
                          setState={setProfessionals} title={"Professional User"} headers={tableHeaders}
                          data={professionals}
                          getData={getProfessionalUsers}
                          getRow={(professional) => {
                              const tableCells = [
                                  <Avatar
                                      style={{
                                          border: "1px solid grey",
                                          width: "37px",
                                          height: "37px",
                                          cursor: "pointer"
                                      }}
                                      src={!_.isEmpty(professional.profilePicture) ? crud.baseUrl2 + "professional-profiles/" + professional.profilePicture : DefaultUserImage}
                                      onClick={() => setProfessionalImage(professional)}
                                  />,
                                  professional.name,
                                  professional.mobile,
                                  professional.email || "NA",
                                  <div className="d-flex">
                                      {professional?.professional_services?.map(item => <div>
                                          <Chip size="small" className="ml-1"
                                                label={item.categories?.name}/></div>)
                                      }</div>,

                                  moment(professional.createdAt).format(crud.dateFormat),
                                  <span style={{
                                      background: `${professional.isActive ? "#5fdba7" : "#F96161"}`,
                                      padding: "5px 10px",
                                      borderRadius: "10px",
                                      fontSize: "12px",
                                      color: "#FFFFFF"
                                  }}>
                                      {professional.isActive ? "Active" : "Inactive"}</span>
                              ];
                              return {
                                  id: professional.id,
                                  cells: tableCells,
                                  actions: getData => <>
                                      {
                                          $user.hasPermission(CHANGE_PROFESSIONAL_STATUS) &&  <FormControlLabel
                                              className="mt-2 ml-1"
                                              control={
                                                  <Switch color="primary" size="small" checked={professional.isActive}
                                                          onChange={async e => {
                                                              const {checked} = e.target;
                                                              await crud.post("status/web/professional-user", {
                                                                  id: professional.id,
                                                              });
                                                              getData({clear: false});
                                                          }}
                                                  />
                                              }
                                          />
                                      }
                                  </>
                              }
                          }}
                          switchRow={(professional) => new Promise(resolve => {
                              setState(prev => ({
                                  ...prev,
                                  onSave: resolve
                              }));
                              setProfessionals(professional);
                          })}
                          total={total}
            />
            <ImagePreview open={!!professionalImage} close={() => setProfessionalImage(null)}
                          imageUrl={professionalImage?.profilePicture} title={"Professional Image"}
                          imageSize={"100%"} path={"/professional-profiles/"}
                          defaultImage={DefaultUserImage}/>
            <Footer/>
        </>
    )
}

export default ProfessionalUser;

