import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
    DialogTitle, DialogContent, DialogActions, Grid, Divider, Checkbox, FormGroup, FormControlLabel
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {crud} from "../services/CrudFactory";
import {$realTime} from "../services/Websocket";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    }, fieldtitle: {
        minWidth: "300px !important", maxHeight: "250px"
    }
}));


export default function JobsDialog(props) {
    const classes = useStyles();
    const [order, setOrder] = useState({});
    const [activeProfessionals, setActiveProfessionals] = useState([]);
    const [professionalIds, setProfessionalIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const retrieveProfessionals = async () => {
        const {data: {active_professionals}} = await crud.get("retrieve/web/professionals-for-assign-jobs", {
            categoryId: order?.categoryId
        });
        setActiveProfessionals(active_professionals);
    }

    const assignOrder = async () => {
        try {
            setLoading(true);
            await crud.create("create/web/assign-jobs", {
                professionalIds,
                orderId: order?.id
            });
            setProfessionalIds([]);
            props.onClose();
            props.onSave();
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        return $realTime.subscribe("shift_start_professional", "update", (professional) => {
            setActiveProfessionals(prev => {
                return [...prev, professional];
            })
        }).cancel;
    }, []);
    useEffect(() => {
        return $realTime.subscribe("end_shift", "update", (shift) => {
            setActiveProfessionals(prev => {
                const temp = [...prev];
                return temp.filter(s => s.professionalId !== shift.professionalId);
            });
        }).cancel;
    }, []);

    useEffect(() => {
        retrieveProfessionals();
    }, [order]);

    return (<Fragment>
        <Dialog
            // onClose={props.close}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            maxWidth={"sm"}
            TransitionProps={{
                onEnter: () => {
                    setOrder(props.assign);
                    if (props.assign?.job_order_professionals?.length)
                        setProfessionalIds(props.assign?.job_order_professionals?.map(item => item?.professionalId))
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" className="font-weight-bolder">Job Assign</Typography>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} className={classes.fieldtitle}>
                        <FormGroup row>
                            {
                                activeProfessionals?.map(item => <FormControlLabel
                                    checked={professionalIds.includes(item?.professionalId)}
                                    onChange={() => {
                                        setProfessionalIds(prev => {
                                            const temp = [...prev];
                                            if (temp?.includes(item?.professionalId)) {
                                                return temp.filter(s => s !== item?.professionalId);
                                            } else {
                                                return [...prev, item?.professionalId];
                                            }
                                        })
                                    }}
                                    control={<Checkbox
                                        name="permission_ids"
                                        color="primary"

                                        className="pr-2"
                                    />}
                                    label={item.professional?.name}
                                />)
                            }
                        </FormGroup>
                    </Grid>
                </Grid>
                {
                    !activeProfessionals.length &&
                    <Typography className="p-2 w-100 no-record-color text-center font-weight-bold">
                        No Professional found
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        props.onClose()
                        setProfessionalIds([]);
                    }}
                    style={{textTransform: "none"}}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => assignOrder()}
                    style={{textTransform: "none"}}
                >
                    {loading ? "Sending..." : "Send"}
                </Button>
            </DialogActions>
        </Dialog>
    </Fragment>);
}
