import React, {useEffect, useState} from "react";
import {
    Grid,
    Container,
    Button,
    TextareaAutosize,
    Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {crud} from "../services/CrudFactory";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import {CUSTOMER, PROFESSIONAL,UPDATE_ABOUT} from "../constant";
import {$user} from "../services/UserFactory";
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "40px",
        marginBottom: "60px",
    },
    textareaAutosize: {
        width: "100%",
        height: "350px !important",
        padding: "10px",
        borderRadius: "10px",
    },
    buttonSubmit: {
        float: "right",
    },
}));

export default function AboutUs() {
    const classes = useStyles();
    const [abouts, setAbouts] = useState([]);
    const [customerAbout, setCustomerAbout] = useState({});
    const [professionalAbout, setProfessionalAbout] = useState({});
    const [loading, setLoading] = useState(false);
    const getAbouts = async () => {
        const {data: {abouts}} = await crud.get("retrieve/web/abouts");
        setAbouts(abouts);
        setCustomerAbout(prev => {
            return {
                ...prev,
                "about": abouts?.find(s => s.type === CUSTOMER)?.about,
            }
        });
        setProfessionalAbout(prev => {
            return {...prev, "about": abouts?.find(s => s.type === PROFESSIONAL)?.about}
        })
    }
    const updateAbout = async (about) => {
        try {
            setLoading(true);
            await crud.update("/update/web/about", about)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        getAbouts()
    }, [])

    return (
        <>
            <Appbar/>
            <Drawer/>
            <Container maxWidth="xl" className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">About Customer App</Typography>
                        <TextareaAutosize
                            maxRows={1}
                            color="primary"
                            aria-label="maximum height"
                            placeholder="Type Here..."
                            name="description"
                            value={customerAbout.about || ""}
                            onChange={e => {
                                setCustomerAbout(prev => {
                                    return {
                                        ...prev,
                                        "type": CUSTOMER,
                                        id: abouts?.find(s => s.type === CUSTOMER)?.id,
                                        about: e.target.value
                                    }

                                })
                            }}
                            className={classes.textareaAutosize}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">About Professional App</Typography>
                        <TextareaAutosize
                            maxRows={1}
                            value={professionalAbout?.about || ""}
                            aria-label="maximum height"
                            placeholder="Type Here..."
                            name="description"
                            onChange={e => {
                                setProfessionalAbout(prev => {
                                    return {
                                        ...prev,
                                        "type": PROFESSIONAL,
                                        id: abouts?.find(s => s.type === PROFESSIONAL)?.id,
                                        about: e.target.value
                                    }

                                })
                            }}
                            className={classes.textareaAutosize}
                        />
                    </Grid>
                </Grid>
                {
                    $user.hasPermission(UPDATE_ABOUT) && <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={!customerAbout.type}
                                color="primary"
                                onClick={() => updateAbout(customerAbout)}
                                className={classes.buttonSubmit}
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!professionalAbout.type}

                                className={classes.buttonSubmit}
                                onClick={() => updateAbout(professionalAbout)}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Container>
            <Footer/>
        </>
    );
}
