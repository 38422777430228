import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {Typography} from "@material-ui/core";
import {crud} from "../services/CrudFactory";

export default function Alertnotification(props) {
    //================= user sent all notification api call============
    // const [saving, setSaving] = useState(false);
    const [token, setToken] = useState({});
    const {notificationId} = props;

    const getToken = async () => {

        const {data} = await crud.get("retrieve/get-fcm", {
            type: props.notificationId?.sendTo === "Professional" ? "Professional" : "Customer"
        })
        setToken(data);
    }

    const send = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", props?.notificationId?.sendTo === "Professional" ? "key=AAAAnxU_P98:APA91bE6NN4rXjohPkUxFMJ0YO6T_fF8zttXycPLfc9y6-k1NGLGKlwoJgGyqjJGk5ODsIKKSz6QuUMNACqGx4lrWr3anDGWc5egvk6UFb8RBP1jtHDSvSuKMWihvuoKz9890l9QNCo5" : "key=AAAAUBJZdZE:APA91bEEcApG8YAr3G_3FQcddpxBj2iO11jWes0EX9ssJ5EOqY6Qyepjdm6YrMy264cc_6ot0P1-lcSV7hwT0rbwLTvlSv51j6CxbM43t_zrm8PS_skfl18nMjf1YT86_sNDaQyDxZHy");

        const raw = JSON.stringify({
            "priority": "HIGH",
            "data": {},
            "notification": {
                "body": notificationId.body,
                "title": notificationId.title,
                "image": crud.baseUrl2 + "images/notifications/" + notificationId.image
            },
            "registration_ids": token
            // "to": "cWqtkk6_RwSpXRYEN76fWs:APA91bEdPz8YO4AzTRru4146jt5g4gSykUdwAJo6XKgwAacCH8I7A5q-k3dzuBurnVYS2drq_0M9ZzUCro7-cGhqYH6pBEh9IDvNgxwWHR9Reh0Qiso8oo1Kp2OIrKKaFLvEROvldJzM"
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://fcm..googleapiscom/fcm/send", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        props.getUser();
        setToken({});
        props.close();
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionProps={
                    {
                        onEnter: () => {
                            getToken();
                        }
                    }
                }
                // maxWidth={"sm"}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className="text-center">
                            <ErrorOutlineIcon style={{fontSize: "100px"}}/>
                            <Typography variant="h3" className="mb-3 pl-5 pr-5">Are you sure?</Typography>
                            <Typography variant="body1">do you want to send notification!</Typography>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" size="small" onClick={() => send()}
                            style={{textTransform: "none"}}>
                        Yes
                    </Button>
                    <Button onClick={() => {
                        props.close();
                        setToken({});
                    }} color="primary" style={{textTransform: "none"}} size="small"
                            autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
