import {find, isArray, isMatch, remove} from "lodash";
import {crud} from "./CrudFactory";

export class RealTimeFactory {
    callbacks = [];
    socket;
    pingInterval;

    closeConnection() {
        if (this.socket) {
            this.socket.onclose = null;
            this.socket.close();
        }
    }

    openSocket() {
        clearInterval(this.pingInterval);

        const socket = new WebSocket("wss://services4home.in/realtime");
        socket.onmessage = ({data: payload}) => {
            const {data, topic, channel} = JSON.parse(payload);
            this.callbacks
                .forEach((listener) => {
                    if ((!listener.predicate || isMatch(data, listener.predicate)) && listener.channel === channel && listener.topic === topic)
                        listener.callback(data, {
                            topic,
                            channel
                        });
                });
        };

        this.pingInterval = setInterval(() => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({
                    action: "ping",
                    date: new Date()
                }));
            }
        }, 59000);

        socket.onclose = async () => {
            console.log("ws3");
            this.openSocket();
        };

        socket.onopen = () => {
            this.callbacks.forEach(({channel, predicate}) => {
                this.sendSubscribeRequest(channel, predicate);
            });
        };

        this.socket = socket;
    }

    subscribe(channel, topic, callback, predicate) {
        if (isArray(topic)) {
            topic.forEach(topic => {
                this.callbacks = [...this.callbacks, {channel, topic, callback, predicate}]
            });
        } else {
            this.callbacks = [...this.callbacks, {channel, topic, callback, predicate}]
        }

        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.sendSubscribeRequest(channel, predicate);
        } else if (!this.socket || this.socket.readyState === WebSocket.CLOSED || this.socket.readyState === WebSocket.CLOSING) {
            this.openSocket();
        }

        return {
            cancel: () => {
                remove(this.callbacks, {callback});
                if (!find(this.callbacks, {
                    channel,
                    predicate
                })) ;
                this.socket.readyState === WebSocket.OPEN && this.socket.send(JSON.stringify({
                    action: "unsubscribe",
                    auth: "123456789",
                    channel,
                    predicate
                }));
            }
        };
    }

    sendSubscribeRequest(channel, predicate) {
        this.socket.send(JSON.stringify({
            action: "subscribe",
            auth: "123456789",
            channel,
            predicate
        }));
    }
}

export const $realTime = new RealTimeFactory();
