import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import classNames from "classnames";
import {
    Container,
    Typography,
    Grid,
    Tooltip,
    Switch,
    Checkbox,
    Fab,
    InputAdornment,
    TextField, MenuItem, debounce
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
    Edit,
    Add,
    Delete,
} from "@material-ui/icons";
import {Pagination} from "pagination-ui";
import moment from "moment";
import {Tr,Td} from "../components/index";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "10px",
        marginBottom: "60px"
    },
    container: {
        maxHeight: 460
    },
    cell: {
        color: "inherit"
    },
    row: {
        '&:hover': {
            backgroundColor: "rgba(245,245,245,0.97)"
        }
    },
    add: {
        position: "fixed",
        bottom: "90px",
        right: "15px",
        // backgroundColor: "#e466a3",
        color: "#fff",
        zIndex: 2,
    },
}));

export default function TableDataCom(props) {
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [type, setType] = useState("");
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({fromDate: ""});
    const [filters, setFilters] = useState({
        search: "",
        from_date: "",
        to_date: "",
    })


    const {title, headers: headersProp, data, getRow, selectedRows, onSelect, total, status: showStatusBox, type: showTypeBox, search: showSearchBox, from_date: showFromDate, to_date: showToDate, ActiveStatus: showStatusActiveInactive} = props;

    const hasActions = props.editRow || props.deleteRow || props.switchRow;

    const refreshData = async ({clear = false} = {}) => {
        if (!props.getData)
            return;
        if (clear)
            setLoading(true);
        await props.getData({page, limit, type, filters, search});
        setLoading(false);
    };

    const setFilter = (key, value) => {
        setFilters(prev => {
            return {
                ...prev,
                [key]: value === "any" ? "" : value
            };
        });
    };

    const debounceSetFilter = useCallback(debounce(setFilter, 800), []);

    useEffect(() => {
        if (search) {
            debounceSetFilter("search", search);
        }
        refreshData({clear: true});
    }, [page, limit, type, filters, search]);


    let rows = [];

    let headers = headersProp.map(header => {
        let object;
        if (typeof header === 'string') {
            object = {
                id: header,
                content: header
            };
        } else {
            object = header;
        }
        object = {
            width: `${90 / headersProp.length}%`,
            ...object
        };
        return object;
    });

    if (hasActions) {
        headers.push({
            id: "__actions",
            content: "Actions",
            width: "auto",
        })
    }

    let headersCount = headers.length;
    if (selectedRows)
        headersCount++;

    rows = loading ? new Array(Number(limit)).fill(null).map(function (val, id) {
        return {
            id,
            cells: new Array(headersCount).fill(<Skeleton/>)
        }
    }) : data.map(object => {
            const row = getRow(object, () => {
            });

            if (hasActions) {
                const actions = <Grid container wrap="nowrap" alignItems="center">
                    {
                        props.editRow &&
                        <Tooltip placement="top" title={"Edit"}>
                            <a
                                className="pl-2 pr-2 action pointer"
                                style={{color: "var(--main-color)"}}
                                onClick={() => props.editRow(object).then(() => refreshData())}
                            >
                                <Edit fontSize={'small'}/>
                            </a>
                        </Tooltip>
                    }
                    {
                        props.deleteRow &&
                        <Tooltip placement="top" title={"Delete"}>
                            <a
                                className="pl-2 action pr-2 pointer"
                                style={{color: "var(--deleteIcon-color)"}}
                                onClick={() => props.deleteRow(object).then(() => refreshData())}
                            >
                                <Delete fontSize={'small'}/>
                            </a>
                        </Tooltip>
                    }
                    {
                        props.switchRow &&
                        <Tooltip placement="top" title={"Delete"}>
                            <a
                                className="pl-2 action pr-2 pointer"
                                style={{color: "var(--deleteIcon-color)", display: "none"}}
                                onClick={() => props.switchRow(object).then(() => refreshData())}
                            >
                                <Switch fontSize={'small'}/>
                            </a>
                        </Tooltip>
                    }
                    {row.actions && row.actions(refreshData)}
                </Grid>;
                row.cells.push(actions);
            }
            return row;
        }
    );

    if (selectedRows) {
        headers.unshift({
            id: "__checkbox",
            content: <Checkbox
                color="primary"
                disabled={loading}
                checked={!!selectedRows.length && rows.every(row => selectedRows.includes(row.id))}
                onChange={e => {
                    const {checked} = e.target;
                    onSelect(checked ? rows.map(row => row.id) : []);
                }}
            />
        });
    }
    useEffect(() => {
        const new_errors = {dateFrom: ""};
        if (moment(filters.from_date).isAfter(filters.to_date))
            new_errors.dateFrom = "From Date should be less than Date To";
        setErrors(prev => {
            return {...prev, ...new_errors}
        });
        if (new_errors.dateFrom) {
            props.setState([]);
            return;
        }
    }, [page, limit, filters]);
    useEffect(() => {
        console.log(errors, '------------errors------------');
    }, [errors]);
    return (
        <Fragment>
            <Container maxWidth="xl" style={{marginTop: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item className="flex-grow-1">
                        <Typography variant="h6" className="p-2 font-weight-bold">
                            {title}
                        </Typography>
                    </Grid>
                    {showTypeBox &&
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="Question For"
                            name="type"
                            onChange={debounce((e, {type}) => setType(e.target.value === "any" ? "" : e.target.value), 800)}
                        >
                            <MenuItem value="any">All</MenuItem>
                            <MenuItem value="customer">Customer User</MenuItem>
                            <MenuItem value="professional">Professional</MenuItem>
                        </TextField>
                    </Grid>
                    }
                    {
                        showFromDate && <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                style={{
                                    width: "100%",
                                }}
                                label="From Date"
                                InputLabelProps={{shrink: true}}
                                error={!!errors.dateFrom}
                                helperText={errors.dateFrom}
                                variant="outlined"
                                id="datetime-local"
                                size="small"
                                type="date"
                                format={"DD/MM/YYYY"}
                                onChange={(e) => setFilter("from_date", e.target.value)}
                            />
                        </Grid>
                    }
                    {
                        showToDate && <Grid item xs={12} sm={4} md={2}>
                                <TextField
                                    style={{
                                        width: "100%",
                                    }}
                                    label="To Date"
                                    InputLabelProps={{shrink: true}}
                                    variant="outlined"
                                    id="datetime-local"
                                    size="small"
                                    type="date"
                                    format={"DD/MM/YYYY"}
                                    onChange={(e) => setFilter("to_date", e.target.value)}
                                />
                        </Grid>
                    }

                    {
                        showStatusBox && <Grid item xs={12} sm={3} md={2}>
                            <TextField
                                select
                                label="Status"
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={(e) => setFilter("status", e.target.value)}
                            >
                                <MenuItem value="">Any</MenuItem>
                                <MenuItem value="incomplete">Incomplete</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="reject">Reject</MenuItem>
                                <MenuItem value="approve">Approved</MenuItem>
                            </TextField>
                        </Grid>
                    }
                    {
                        showStatusActiveInactive && <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                variant="outlined"
                                label="Status"
                                onChange={(e) => setFilter("status", e.target.value)}
                            >
                                <MenuItem value="any">All</MenuItem>
                                <MenuItem value="1">Active</MenuItem>
                                <MenuItem value="0">Inactive</MenuItem>
                            </TextField>
                        </Grid>
                    }

                    {
                        showSearchBox && <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                variant="outlined"
                                size="small"
                                color="primary"
                                fullWidth
                                placeholder="Search...."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary"/>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => setFilter("search", e.target.value)}
                            />
                        </Grid>
                    }
                </Grid>

                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader size="small">
                            {!!headers.length && <TableHead>
                                <TableRow>
                                    {headers.map(({id, content, width}) => (
                                        <TableCell style={{background: "#e466a3", width}} key={id}>{content}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>}

                            <TableBody>
                                {
                                    rows.map(row => {
                                        const {Component = "tr"} = row;
                                        return <Tr key={row.id} {...row.props}
                                                          className={classNames(classes.row, row.props && row.props.className)}>
                                            {
                                                row.cells.map((cell, index) => <Td className={classes.cell}
                                                                                          key={index}>{cell}</Td>)
                                            }
                                        </Tr>;
                                    })
                                }
                                {
                                    !rows.length && <TableRow>
                                        <TableCell className="text-center" colSpan={headers.length}>No
                                            Records</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        limit={limit}
                        limitRange
                        total={total}
                        page={page}
                        onPageChange={setPage}
                        onLimitChange={setLimit}
                    />

                </Paper>
                {
                    props.createRow &&
                    <Fab
                        className={classes.add} color="primary"
                        onClick={() => props.createRow().then(() => refreshData())}
                    >
                        <Add/>
                    </Fab>
                }
            </Container>
        </Fragment>
    );
}
