import React, {Fragment, useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import {
    Container,
    Typography,
    TextField,
    Grid,
    MenuItem,
    Tooltip,
    IconButton, debounce,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {Delete} from "@material-ui/icons";
import KycDialog from "../dialog/KycDialog";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import {crud} from "../services/CrudFactory";
import {$user} from "../services/UserFactory";
import {Pagination} from "pagination-ui";
import SimpleBackdrop from "../components/SimpleBackdrop";
import {Tr, Td} from "../components/index";
import SkeletonUse from "../components/SkeletonUse";
import {UPDATE_KYC} from "../constant";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "20px",
        marginBottom: "60px"
    },
    container: {
        maxHeight: 440
    }
}));

export default function Kyc() {
    const classes = useStyles();
    const [professioanals, setProfessionals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [professional, setProfessional] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({});
    const retrieveProfessionals = async () => {
        try {
            setLoading(true);
            const {data: {professionals, total}} = await crud.get("retrieve/web/professionals-kyc", {
                ...filters,
                limit,
                page,
            });
            setTotal(total);
            setProfessionals(professionals);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        retrieveProfessionals();
    }, [filters, page, limit]);
    return (
        <Fragment>
            <Appbar/>
            <Drawer/>
            <Container maxWidth="xl" style={{marginTop: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={7}>
                        <Typography variant="h6" className="font-weight-bolder">
                            Professional Kyc
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={3} md={2}>
                        <TextField
                            select
                            label="Status"
                            fullWidth
                            variant="outlined"
                            size="small"
                            onChange={e => setFilters(prev => {
                                const {value} = e.target;
                                return {...prev, "status": value === "any" ? "" : value}
                            })}
                            name="status"
                        >
                            <MenuItem value="any">All</MenuItem>
                            <MenuItem value="incomplete">Incomplete</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="reject">Reject</MenuItem>
                            <MenuItem value="approve">Approved</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={5} md={3}>
                        <TextField
                            variant="outlined"
                            size="small"
                            color="primary"
                            fullWidth
                            placeholder="Search...."
                            onChange={e => {
                                setFilters(prev => {
                                    return {...prev, "search": e.target.value}
                                });
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary"/>
                                    </InputAdornment>
                                ),
                            }}
                            name="search"
                        />
                    </Grid>
                </Grid>

                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Professional Name</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Create At</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell colSpan={2} className="text-center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    !loading ?
                                        professioanals.map((professional, i) => (
                                            <>
                                                <Tr hover key={i}>
                                                    <Td>{professional?.name || ""}</Td>
                                                    <Td>{professional?.mobile || "NA"}</Td>
                                                    <Td>{professional?.email || "NA"}</Td>
                                                    <Td>{moment(professional.createdAt).format(crud.dateFormat)}</Td>
                                                    <Td>          <span style={{
                                                        background: `${professional.kycStatus === "approve" ? "#5fdba7" : professional.kycStatus === "reject" ? "#F96161" : professional.kycStatus === "incomplete" ? "#e466a3" : "#d8d80b"}`,
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "12px",
                                                        color: "#FFFFFF"
                                                    }}>
                                      {professional.kycStatus}</span></Td>

                                                    {
                                                        $user.hasPermission(UPDATE_KYC) &&
                                                        <TableCell className="text-center">
                                                            {
                                                                professional?.kycStatus !== "incomplete" &&
                                                                <Tooltip onClick={() => setProfessional(professional)}
                                                                         style={{cursor: "pointer"}}
                                                                         title="View Professional Kyc">
                                                                    <VisibilityIcon color="primary"/>
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                    }
                                                    <TableCell>
                                                        <Tooltip onClick={async ()=>{
                                                            await crud.confirm({
                                                                textContent:"Do you want to delete the professionals?"
                                                            });
                                                            await crud.post("delete/web/professional-kyc",{
                                                                id:professional?.id
                                                            });
                                                            retrieveProfessionals();
                                                        }} style={{cursor: "pointer"}}
                                                                 title="Delete Professional Kyc">
                                                            <Delete color="primary"/>
                                                        </Tooltip>
                                                    </TableCell>
                                                </Tr>

                                            </>
                                        )) : <SkeletonUse cellCount={6} limit={10}/>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        !professioanals.length && !loading &&
                        <Typography className="p-2 w-100 no-record-color text-center font-weight-bold">
                            No records found
                        </Typography>
                    }

                    <Pagination
                        limit={limit}
                        limitRange
                        total={total}
                        page={page}
                        onPageChange={setPage}
                        onLimitChange={setLimit}
                    />
                </Paper>
            </Container>
            <KycDialog
                open={!!professional}
                onClose={() => setProfessional(null)}
                professional={professional}
                getProfessional={retrieveProfessionals}
            />
            <Footer/>
        </Fragment>
    );
}
