import React, {useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {crud} from "../services/CrudFactory";

const PrivateRoute = ({children}) => {
    const history = useNavigate();


    const checkUser = async () => {
        const token = await localStorage.getItem("login_token");
        const {data: {token_found}} = await crud.get("retrieve/web/check-active", {token});
        if (!!!token_found) {
            localStorage.clear();
            history("/login");
        }
    };

    useEffect(() => {
        checkUser();
    }, [children]);

    const isAuthenticated = localStorage.getItem("user-info");
    return isAuthenticated ? children : <Navigate to="/"/>
};

export default PrivateRoute;
