import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
    makeStyles,
    DialogTitle,
    DialogContent,
    Grid,
    Divider,
    CardHeader,
    Box,
    Avatar
} from "@material-ui/core";
import ModalImage from "react-modal-image";
import moment from "moment";
import {crud} from "../services/CrudFactory";
import _ from 'lodash';
import Default from '../images/default.jpg'

const useStyles = makeStyles((theme) => ({
    input: {
        display: "none",
    },
    grid: {
        display: "flex",
    },
    media: {
        width: "100%",
        height: "50vh",
        borderRadius: "10px",
    },
    boxBorder: {
        border: "1px solid grey",
        width: "100%",
        padding: "5px",
        minheight: "20%",
        borderRadius: "10px",
        marginBottom: "10px",
    },
}));

export default function NotificationDialog(props) {
    const classes = useStyles();
    const [support, setSupport] = useState({});
    const [saving, setSaving] = useState(false);

    const valueChange = (e) => {
        const {value, name} = e.target;
        setSupport(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const save = async () => {
        try {
            setSaving(true);
            await crud.post("update/web/reply-professional-ticket", support);
            setSupport({});
            props.close();
            props.onSave();
        } finally {
            setSaving(false);
        }
    };


    return (
        <div>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth={"md"}
                TransitionProps={
                    {
                        onEnter: () => {
                            setSupport(props.support);
                        }
                    }
                }
            >
                <DialogTitle>
                    <Typography variant="h5">Support Ticket</Typography>
                </DialogTitle>
                <Divider/>
                <DialogContent className="mb-3">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box style={{width:"100%"}}>
                                {!!support?.image ?
                                    <ModalImage
                                        className={classes.media}
                                        alt="Here is the caption"
                                        small={crud.baseUrl2 + "/support-tickets-pictures/" + support?.image}
                                    /> : <ModalImage
                                        className={classes.media}
                                        alt="Here is the caption"
                                        small={Default}
                                        medium={Default}
                                        large={Default}
                                    />
                                }
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box className={classes.boxBorder}>
                                <CardHeader
                                    avatar={<Avatar src={crud.baseUrl2 + "images/professionals/" + support?.Professionals?.profile_picture} />}
                                    title={support?.professional?.name}
                                />
                                <br/>
                                <Typography
                                    variant="caption"
                                    className="text-center ml-4"
                                    style={{fontSize: "12px"}}
                                >
                                    Date&nbsp;&&nbsp;Time:{" "}
                                    {moment(support?.createdAt).format(
                                        "DD:MM:YYYY hh:mm A"
                                    )}
                                </Typography>
                                <br/>
                                <Typography variant="caption" className="ml-4">
                                    Mobile: {support?.professional?.mobile}
                                </Typography>
                                <br/>
                                <Typography variant="caption" className="ml-4">
                                    Email: {support?.professional?.email}
                                </Typography>
                            </Box>

                            <Box className={classes.boxBorder}>
                                <Typography variant="caption" style={{fontSize: "16px"}}>
                                    Ticket Number: {support?.id}
                                </Typography>
                                <br/>
                                <Typography variant="caption">
                                    <span className="font-weight-bold">Subject: &nbsp;</span>
                                    {support?.subject}
                                </Typography>
                                <br/>
                                <Typography variant="caption">
                    <span className="font-weight-bold">
                      Description: &nbsp;
                    </span>
                                    {support?.description}
                                </Typography>
                            </Box>

                            <TextareaAutosize
                                maxRows={1}
                                placeholder="Answer Type Here"
                                style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "10px",
                                    outline: "none",
                                    padding: "5px",
                                    resize: "none",
                                }}
                                name="reply"
                                value={support.reply || ""}
                                disabled={!!props?.support?.reply}
                                onChange={valueChange}
                            />

                            <Button
                                variant="contained"
                                onClick={props.close}
                                color="primary"
                                size="small"
                                className="float-right mt-2"
                                style={{textTransform:"none"}}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            {!!!props?.support?.reply &&
                                <Button
                                    variant="contained"
                                    disabled={saving}
                                    onClick={() => save()}
                                    color="primary"
                                    size="small"
                                    type="submit"
                                    className="float-right mt-2 ml-2 mr-2"
                                    style={{textTransform: "none"}}
                                    color="primary"
                                >
                                    {saving ? "Replying..." : "Reply"}
                                </Button>
                            }

                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}
