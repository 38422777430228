import React, {Fragment, useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {Container, IconButton, Typography} from "@material-ui/core";
import SupportDialog from "../dialog/SupportDialog";
import {Pagination} from "pagination-ui";
import moment from "moment";
import {crud} from "../services/CrudFactory";
import {$user} from "../services/UserFactory";
import {REPLY_SUPPORT_TICKET} from "../constant";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import SkeletonUse from "../components/SkeletonUse";
import {Td, Tr} from "../components";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplyIcon from '@material-ui/icons/Reply';
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%", marginTop: "20px",
    }, container: {
        maxHeight: 440
    }
}));

export default function SupportTicket() {
    const classes = useStyles();
    const [support, setSupport] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const getSupports = async () => {
        try {
            setLoading(true);
            const {data: {tickets, total}} = await crud.get("retrieve/web/professional-tickets", {
                limit, page,
            });
            setTickets(tickets);
            setTotal(total);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSupports();
    }, [limit, page]);


    return (<Fragment>
        <Appbar/>
        <Drawer/>
        <Container maxWidth="xl" style={{marginTop: "20px"}}>
            <Typography variant="h6" className="font-weight-bolder">
                Support Ticket
            </Typography>

            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Create At</TableCell>
                                <TableCell>Ticket No.</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !loading ?
                                    tickets?.map((item, i) => (<Tr hover key={i}>
                                        <Td>{item.professional?.name}</Td>
                                        <Td>{item.subject}</Td>
                                        <Td>{item.description}</Td>
                                        <Td>
                                            {moment(item.createdAt).format("lll")}
                                        </Td>
                                        <Td>{item.id}</Td>
                                        <Td><span style={{
                                            background: `${item.status === "pending" ? "#DFCF14" : "#A7F1A7"}`,
                                            padding: "5px 10px",
                                            borderRadius: "10px",
                                            fontSize: "12px",
                                            color: "#FFFFFF"
                                        }}>{item.status.toUpperCase()}</span></Td>
                                        <Td>
                                            {$user.hasPermission(REPLY_SUPPORT_TICKET) && item.status === "pending" ?
                                                <IconButton size="small" onClick={() => setSupport(item)}>
                                                    <ReplyIcon color="primary"/>
                                                </IconButton> :
                                                <IconButton size="small" onClick={() => setSupport(item)}>
                                                    <VisibilityIcon color="primary"/>
                                                </IconButton>}
                                        </Td>
                                    </Tr>)) : <SkeletonUse limit={10} cellCount={7}/>}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!tickets.length && !loading && <Typography className="p-2 w-100 no-record-color text-center font-weight-bold">
                    No records found
                </Typography>}
                <Pagination
                    limit={limit}
                    limitRange
                    total={total}
                    page={page}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                />
            </Paper>
        </Container>
        <SupportDialog
            open={!!support}
            close={() => setSupport(null)}
            support={support}
            onSave={getSupports}
        />
        <Footer/>
    </Fragment>);
}
