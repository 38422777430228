import React, {Fragment, useEffect, useState} from "react";
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container,
    Typography,
    TextField,
    Grid,
    MenuItem,
    FormControlLabel,
    IconButton, Avatar, Switch, Tooltip,
} from "@material-ui/core";
import {Edit} from "@material-ui/icons";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import AddButton from "../components/AddButton";
import {Pagination} from "pagination-ui";
import SkeletonUse from "../components/SkeletonUse";
import {useLocation} from "react-router-dom";
import {Tr, Td} from "../components";
import SubCategoryDialog from "../dialog/SubCategoryDialog";
import {
    CREATE_SUB_CATEGORIES,
    UPDATE_SUB_CATEGORIES,
    CHANGE_SUB_CATEGORY_STATUS
} from "../constant";
import {$user} from "../services/UserFactory";
export default function SubCategories() {
    const [subCategory, setSubCategory] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [errors, setErrors] = useState({fromDate: ""});
    const [page, setPage] = useState(1);
    const location = useLocation();
    const getSubCategories = async () => {
        const {data: {sub_categories, total}} = await crud.get("retrieve/web/sub-categories", {
            ...filters,
            limit,
            categoryId: location.state.categoryId,
            page
        });
        setSubCategories(sub_categories);
        setLoading(false);
        setTotal(total);
    }

    useEffect(() => {
        getSubCategories();
    }, [filters, limit, page]);

    const setFilter = (key, value) => {
        setFilters(prev => {
            return {
                ...prev,
                [key]: value === "any" ? "" : value
            };
        });
    };


    useEffect(() => {
        const new_errors = {dateFrom: ""};
        if (moment(filters.from_date).isAfter(filters.to_date))
            new_errors.dateFrom = "From Date should be less than Date To";
        setErrors(prev => {
            return {...prev, ...new_errors}
        });
        if (new_errors.dateFrom) {
            setSubCategories([]);
            return;
        }
    }, [page, limit, filters]);
    useEffect(() => {
        setLoading(true);
    }, []);
    return (
        <Fragment>
            <Appbar/>
            <Drawer/>

            <Container maxWidth="xl" style={{marginTop: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={6}>
                        <Typography variant="h6" className="font-weight-bolder">
                            Sub Categories
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            style={{
                                width: "100%",
                            }}
                            error={!!errors.dateFrom}
                            helperText={errors.dateFrom}
                            label="From Date"
                            InputLabelProps={{shrink: true}}
                            variant="outlined"
                            id="datetime-local"
                            size="small"
                            type="date"
                            format={"DD/MM/YYYY"}
                            onChange={(e) => setFilter("from_date", e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            style={{
                                width: "100%",
                            }}
                            variant="outlined"
                            label="To Date"
                            InputLabelProps={{shrink: true}}
                            id="datetime-local"
                            size="small"
                            type="date"
                            format={"DD/MM/YYYY"}
                            onChange={(e) => setFilter("to_date", e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="Status"
                            name="status"
                            onChange={(e) => setFilter("status", e.target.value)}
                        >
                            <MenuItem value="any">All</MenuItem>
                            <MenuItem value="1">Active</MenuItem>
                            <MenuItem value="0">Inactive</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <Paper className="mt-3">
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>SubCategory Photo</TableCell>
                                    <TableCell>SubCategory Name</TableCell>
                                    <TableCell>Create At</TableCell>
                                    <TableCell>Create By</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell className="text-center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    !loading ?
                                        subCategories.map((item, i) => (
                                            <>
                                                <Tr hover key={i}>
                                                    <Td>
                                                        <Avatar
                                                            alt="Remy Sharp"
                                                            src={crud.baseUrl2 + "/sub-categories/" + item.image}
                                                        />
                                                    </Td>
                                                    <Td>{item.name}</Td>
                                                    <Td>{moment(item.createdAt).format(crud.dateFormat)}</Td>
                                                    <Td>{item.created_by?.name}</Td>
                                                    <Td>
                                                    <span style={{
                                                        background: `${item.status ? "#5fdba7" : "#F96161"}`,
                                                        padding: "5px 10px",
                                                        borderRadius: "10px",
                                                        fontSize: "12px",
                                                        color: "#FFFFFF"
                                                    }}>
                                                        {item.status ? "Active" : "Inactive"}</span>
                                                    </Td>
                                                    <TableCell className="text-center">
                                                        {
                                                            $user.hasPermission(UPDATE_SUB_CATEGORIES) && <Tooltip title="Edit">
                                                                <IconButton size="small"
                                                                            onClick={() => setSubCategory(item)}>
                                                                    <Edit color="primary"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {
                                                            $user.hasPermission(CHANGE_SUB_CATEGORY_STATUS) && <Tooltip title="Change Status">
                                                                <IconButton size="small">

                                                                    <FormControlLabel
                                                                        className="ml-1"
                                                                        control={
                                                                            <Switch color="primary" size="small"
                                                                                    checked={item.status}
                                                                                    onChange={async (e) => {
                                                                                        await crud.post("status/web/sub-category", {
                                                                                            id: item.id,
                                                                                        });
                                                                                        getSubCategories();
                                                                                    }}
                                                                            />
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </TableCell>
                                                </Tr>

                                            </>
                                        )) : <SkeletonUse cellCount={6} limit={10}/>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        !subCategories.length && !loading &&
                        <Typography className="p-2 w-100 text-center font-weight-bold">
                            No records found
                        </Typography>

                    }
                    <Pagination
                        limit={limit}
                        limitRange
                        total={total}
                        page={page}
                        onPageChange={setPage}
                        onLimitChange={setLimit}
                    />
                </Paper>
            </Container>
            {
                $user.hasPermission(CREATE_SUB_CATEGORIES) && <AddButton handleClickOpen={() => setSubCategory({})}/>
            }
            <SubCategoryDialog categoryId={location.state?.categoryId} open={!!subCategory}
                               onClose={() => setSubCategory(null)} subCategory={subCategory}
                               subCategories={getSubCategories}/>
            <Footer/>
        </Fragment>
    );
}
