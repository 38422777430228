import React, { useEffect } from "react";
import { crud } from "../services/CrudFactory";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const history = useNavigate();
  const UserLogout = async () => {
    const { type } = await crud.post("create/web/logout");
    if (type === "success") {
      localStorage.removeItem("login_token");
      localStorage.clear();
      history("/");
    }
  };

  useEffect(() => {
    UserLogout();
  }, []);

  return (
    <>
      <p className="d-none"></p>
    </>
  );
}
