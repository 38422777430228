// import React from "react";
// import {crud} from "./CrudFactory";
// import {SET_USER} from "../constant";

export class UserFactory {
    get() {
        return JSON.parse(localStorage.getItem("user-info"));
    }

    hasPermission(...code) {
        const permissions = this.get()?.user.permissions || [];
        return !!permissions.find(p => p.code == code);
    }
}

export const $user = new UserFactory();
