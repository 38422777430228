import React, {Fragment, useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {
    Container,
    Typography,
    TextField,
    Grid,
    IconButton,
    MenuItem,
    Avatar,
    debounce,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    InputAdornment
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import AddButton from "../components/AddButton";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import {Pagination} from "pagination-ui";
import DeleteDialog from "../dialog/delete dialog/DeleteDialog";
import Alertnotification from "../dialog/Alertnotification";
import _ from "lodash";
import NotificationDialog from "../dialog/NotificationDialog";
import ImagePreview from "../components/ImagePreview";
import Default from "../images/default.jpg";
import {
    CUSTOMER,
    PROFESSIONAL,
    CREATE_NOTIFICATION,
    DELETE_NOTIFICATION,
    UPDATE_NOTIFICATION,
    SEND_NOTIFICATION
} from "../constant";
import {Edit} from "@material-ui/icons";
import {Td, Tr} from "../components";
import SkeletonUse from "../components/SkeletonUse";
import {$user} from "../services/UserFactory";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%", marginTop: "20px",
    }, container: {
        maxHeight: 460
    }
}));

export default function Notification() {
    const classes = useStyles();
    const [notifications, setNotifications] = useState([]);
    const [notificationImage, setNotificationImage] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({});
    const [sentNotification, setSentNotification] = useState(null);
    const [createNotification, setCreatenotification] = useState(null);
    const [deleteNotification, setDeleteNotification] = useState(null);
    const [loading, setLoading] = useState(false);

    const getUser = async () => {
        const {data: {notifications, total}} = await crud.get("retrieve/web/notifications", {
            limit, page, ...filters
        });
        setNotifications(notifications);
        setLoading(false);
        setTotal(total);
    };

    const setFilter = (key, value) => {
        setFilters(prev => {
            return {
                ...prev, [key]: value === "any" ? "" : value
            };
        });
    };

    const debounceSetFilter = useCallback(debounce(setFilter, 800), []);

    useEffect(() => {
        getUser();
        if (filters.search) {
            debounceSetFilter("search", filters.search);
        }
    }, [limit, page, filters, filters.search]);


    useEffect(() => {
        setLoading(true);
    }, []);
    return (<Fragment>
        <Appbar/>
        <Drawer/>
        <Container maxWidth="xl" style={{marginBottom: "65px", marginTop: "20px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={6}>
                    <Typography variant="h6" className="font-weight-bolder">
                        Notification
                    </Typography>
                </Grid>

                {/*<Grid item xs={12} sm={3} md={2}>*/}
                {/*    <TextField*/}
                {/*        select*/}
                {/*        label="Create By"*/}
                {/*        fullWidth*/}
                {/*        variant="outlined"*/}
                {/*        size="small"*/}
                {/*        onChange={(e) => setFilter("type", e.target.value)}*/}
                {/*    >*/}
                {/*        <MenuItem value="">any</MenuItem>*/}
                {/*        <MenuItem value="1">Admin</MenuItem>*/}
                {/*        <MenuItem value="2">Agent</MenuItem>*/}
                {/*    </TextField>*/}
                {/*</Grid>*/}

                <Grid item md={6} className="d-flex justify-content-end">
                    <TextField
                        select
                        label="Send To"
                        fullWidth
                        variant="outlined"
                        size="small"
                        onChange={(e) => setFilter("type", e.target.value)}
                    >
                        <MenuItem value="any">All</MenuItem>
                        <MenuItem value={CUSTOMER}>Customer</MenuItem>
                        <MenuItem value={PROFESSIONAL}>Professional</MenuItem>
                    </TextField>
                    <TextField
                        variant="outlined"
                        size="small"
                        color="primary"
                        fullWidth
                        className="ml-2"
                        placeholder="Search...."
                        name="search"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <SearchIcon color="primary"/>
                            </InputAdornment>),
                        }}
                        onChange={(e) => setFilter("search", e.target.value)}
                    />
                </Grid>

            </Grid>

            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Image</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Body</TableCell>
                                <TableCell>Send To</TableCell>
                                <TableCell>Create At</TableCell>
                                <TableCell>Create By</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !loading ? notifications.map((item, i) => (<Tr hover key={i}>
                                    <Td>
                                        <Avatar
                                            src={!_.isEmpty(item.image) ? crud.baseUrl2 + "notifications/" + item.image : Default}
                                            onClick={() => setNotificationImage(item)}
                                        />
                                    </Td>
                                    <Td>{item.title}</Td>
                                    <Td>{item.body}</Td>
                                    <Td>{item.type}</Td>
                                    <Td>{moment(item.createdAt).format(crud.dateFormat)}</Td>
                                    <Td>{item.created_by?.name}</Td>
                                    <Td>
                                        {
                                            $user.hasPermission(SEND_NOTIFICATION) &&
                                            <IconButton color="primary" onClick={async () => {
                                                await crud.post("update/web/send-notification", {
                                                    id: item.id
                                                });
                                                getUser()

                                            }}
                                                        className="mr-1">
                                                <SendIcon/>
                                            </IconButton>
                                        }
                                        {
                                            $user.hasPermission(DELETE_NOTIFICATION) &&
                                            <IconButton color="primary" onClick={async () => {
                                                await crud.confirm({
                                                    textContent:"Do you want to delete the notification?"
                                                });
                                                await crud.post("delete/web/notifications",{
                                                  id:item?.id
                                                });
                                                getUser()

                                            }}
                                                        className="ml-1">
                                                <DeleteIcon/>
                                            </IconButton>
                                        }

                                        {
                                            $user.hasPermission(UPDATE_NOTIFICATION) && !item.isSend && <IconButton>
                                                <Edit color="primary"/>
                                            </IconButton>
                                        }
                                    </Td>
                                </Tr>)) : <SkeletonUse limit={10} cellCount={7}/>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                {!notifications.length && !loading &&
                <Typography className="p-2 w-100 no-record-color text-center font-weight-bold">
                    No records found
                </Typography>}
                <Pagination
                    limit={limit}
                    limitRange
                    total={total}
                    page={page}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                />
            </Paper>
        </Container>
        {
            $user.hasPermission(CREATE_NOTIFICATION) && <AddButton handleClickOpen={() => setCreatenotification({})}/>
        }
        <NotificationDialog open={!!createNotification} close={() => setCreatenotification(null)}
                            getNotificationData={getUser}/>

        <Alertnotification
            open={!!sentNotification}
            close={() => setSentNotification(null)}
            notificationId={sentNotification} getUser={getUser}
        />

        <DeleteDialog open={!!deleteNotification} close={() => setDeleteNotification(null)}
                      deleteId={deleteNotification} refresh={getUser} url={"delete/customer-notications"}/>

        <ImagePreview open={!!notificationImage} close={() => setNotificationImage(null)}
                      imageUrl={notificationImage?.image} path={"images/notifications/"} title={"Notification Image"}
                      defaultImage={Default} imageSize={"480px"}/>

        <Footer/>
    </Fragment>);
}
