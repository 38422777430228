import React, { Fragment } from "react";
import { Container, Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[800],
  },
  add: {
    position: "fixed",
    bottom: "90px",
    right: "15px",
    // backgroundColor: "#e466a3",
    color: "#fff",
    zIndex: 2,
  },
}));

const AddButton = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Container className={classes.root}>
        <Fab color="primary" className={classes.add} onClick={props.handleClickOpen}>
          <Add />
        </Fab>
      </Container>
    </Fragment>
  );
};

export default AddButton;
