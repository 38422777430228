import React, {useEffect, useState} from "react";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Divider,
    Slide,
    TextField,
    Button,
    Dialog,
    Typography
} from "@material-ui/core";
import {crud} from "../services/CrudFactory";
import {CloudUpload} from '@material-ui/icons';

export default function CategoryDialog(props) {
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(false);
    const InputChange = (e) => {
        const {name, value} = e.target;
        setParams({...params, [name]: value});
    }



    const categorySumbit = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("id", params?.id || "");
            formData.append("name", params.name || "");
            formData.append("photo", params.photo || "");
            await crud.post(params?.id ? "update/web/category" : "create/web/category", formData);
            await props.onClose();
            await props.getCategories();
        } finally {
            setLoading(false);
        }
    };



    return (<div>
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={props.open}
            TransitionComponent={Slide}
            maxWidth={"sm"}
            TransitionProps={{
                onEnter: () => {
                    setParams(props?.category);
                }
            }}
        >
            <DialogTitle>
                <Typography variant="h5">{params?.id ? "Update Category" : "Create Category"}</Typography>
            </DialogTitle>
            <Divider/>

            <DialogContent className="mt-2 mb-2">
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} sm={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="off"
                            label="Name"
                            placeholder="name"
                            name="name"
                            value={params.name || ""}
                            onChange={InputChange}
                        />

                    </Grid>

                    <Grid item className="mt-2" xs={12} md={6} lg={6} sm={6}>
                        <input
                            onChange={(e) => {
                                const image = e.target.files[0];
                                setParams(prev => {
                                    return {...prev, "photo": image}
                                });
                            }}
                            accept="image/*"
                            style={{display: "none"}}
                            id="contained-button-file"
                            multiple
                            type="file"
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" style={{width: "250px"}} color="primary" component="span">
                                Upload Image
                            </Button>
                        </label>
                        {
                            params?.photo?.type ? <div style={{width: "100%",}}>
                                <img className="img-fluid" style={{height: "150px", width: "300px"}}
                                     src={window.URL.createObjectURL(params.photo)}/>
                            </div> : params?.photo ? <div style={{width: '100%'}}>
                                <img className="img-fluid" style={{height: "150px", width: "300px"}}
                                     src={`${crud.baseUrl2}categories/${params.photo}`}/>
                            </div> : <div className="d-flex justify-content-center align-items-center"
                                          style={{width: "300px", background: "#e6e6e6", height: "150px"}}>
                                <CloudUpload style={{fontSize: "50px"}}/>
                            </div>
                        }

                    </Grid>


                </Grid>
            </DialogContent>

            <Divider/>

            <DialogActions className="p-2">
                <Button
                    variant="outlined"
                    // onClick={CreateSubmit}
                    onClick={() => props.onClose()}
                    color="primary"
                    size="small"
                    style={{textTransform: "none"}}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => categorySumbit()}
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    style={{textTransform: "none"}}
                >
                    {
                        !params?.id ? (loading ? "Creating..." : "Create") : (loading ? "Updating..." : "Update")
                    }
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
