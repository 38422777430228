import React, {useState, useEffect} from "react";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import TableDataCom from "../components/TableDataCom";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import AddButton from "../components/AddButton";
import ServiceDialog from "../dialog/ServiceDialog";
import {Switch, FormControlLabel} from "@material-ui/core";
import PanelUsersDialog from "../dialog/PanelUsersDialog";
import {$user} from "../services/UserFactory";
import {CREATE_EMPLOYEE, UPDATE_EMPLOYEE, DELETE_EMPLOYEE, CHANGE_EMPLOYEE_STATUS} from "../constant";

const PanelUser = () => {
    const [state, setState] = useState({
        user: null, onSave: null
    });

    const [staffs, setStaffs] = useState([]);
    const [total, setTotal] = useState(0);
    const getUsers = async ({page, limit, status = "", filters}) => {
        try {
            const {data: {staffs, total}} = await crud.get("retrieve/web/staffs", {
                page, limit, status, ...filters
            });
            setStaffs(staffs);
            setTotal(total);
        } catch (error) {
            if (error.message === "You don't allowed for this actions") {
                await crud.notify({
                    type: "error",
                    message: error.message,
                });
            }
        }
    }

    useEffect(() => {
        getUsers();
    }, []);


    const tableHeaders = ["Name", "Username", "Mobile", "Joining Date", "Create At", "Create By", "Status",];
    return (<>
        <Appbar/>
        <Drawer/>

        <TableDataCom from_date ActiveStatus state={staffs} setState={setStaffs} to_date search title={"Panel User"}
                      headers={tableHeaders}
                      data={staffs}
                      getData={getUsers}
                      getRow={(user) => {
                          const tableCells = [
                              user.name,
                              user.userName || "NA",
                              user.mobile,
                              moment(user.dateOfJoining).format(crud.dateFormat),
                              moment(user.createdAt).format(crud.dateFormat),
                              user.staff_created?.name,
                              <span style={{
                                  background: `${user.status ? "#5fdba7" : "#F96161"}`,
                                  padding: "5px 10px",
                                  borderRadius: "10px",
                                  fontSize: "12px",
                                  color: "#FFFFFF"
                              }}>
                                      {user.status ? "Active" : "Inactive"}</span>];
                          return {
                              id: user.id, cells: tableCells, actions: getData => <>
                                  {
                                      $user.hasPermission(CHANGE_EMPLOYEE_STATUS) && <FormControlLabel
                                          className="mt-2 ml-1"
                                          control={<Switch color="primary" size="small" checked={user.status ? 1 : 0}
                                                           onChange={async e => {
                                                               await crud.post("status/web/staff", {
                                                                   id: user.id,
                                                               });
                                                               getData({clear: false});
                                                           }}
                                          />}
                                      />
                                  }
                              </>
                          }
                      }}
                      deleteRow={
                          async ({id}) => {
                              $user.hasPermission(DELETE_EMPLOYEE) &&
                              await crud.confirm();
                              await crud.post(`delete/web/staff`, {id})
                          }
                      }
                      editRow={(user) => new Promise(resolve => {
                          $user.hasPermission(UPDATE_EMPLOYEE) &&
                          setState(prev => ({
                              ...prev, user, onSave: resolve
                          }))
                      })
                      }

                      createRow={


                          (user) => new Promise(resolve => {
                              $user.hasPermission(CREATE_EMPLOYEE) &&
                              setState(prevState => ({
                                  ...prevState, user: {}, onSave: resolve
                              }))
                          })
                      }
                      total={total}
        />
        {/*<ServiceDialog open={!!state.service}*/}
        {/*               close={() => setState(prev => ({...prev, service: null}))}*/}
        {/*               service={state.service}*/}
        {/*               onSave={state.onSave}*/}
        {/*/>*/}
        <PanelUsersDialog open={!!state.user} close={() => setState(prevState => ({...prevState, user: null}))}
                          agent={state.user} onSave={state.onSave}/>
        <Footer/>
    </>)
}

export default PanelUser;

