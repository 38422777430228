import React, {useState, useEffect} from "react";
import Appbar from "../components/Appbar";
import Drawer from "../components/Drawer";
import Footer from "../components/Footer";
import TableDataCom from "../components/TableDataCom";
import {crud} from "../services/CrudFactory";
import moment from "moment";
import {Switch, FormControlLabel, Avatar} from "@material-ui/core";
import _ from "lodash";
import ImagePreview from "../components/ImagePreview";
import DefaultUserImage from "../images/userDefaultImage.jpg"
import {CHANGE_CUSTOMER_STATUS} from "../constant";
import {$user} from "../services/UserFactory";
const CustomerUser = () => {
    const [state, setState] = useState({
        customer: null,
        onSave: null
    });
    const [customerImage, setCustomerImage] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [total, setTotal] = useState(0);
    const getCustomers = async ({page, limit, filters, search}) => {
        const {data: {customers, total}} = await crud.get("retrieve/web/customers", {
            page,
            limit,
            search,
            ...filters
        });
        setCustomers(customers);
        setTotal(total);
    }

    useEffect(() => {
        getCustomers();
    }, []);


    const tableHeaders = [
        "Profile Photo",
        "Customer Name",
        "Mobile",
        "Email",
        "Create At",
        "Status",
    ];
    return (
        <>
            <Appbar/>
            <Drawer/>

            <TableDataCom from_date to_date search title={"Customer User"} state={customers} setState={setCustomers} headers={tableHeaders} data={customers}
                          getData={getCustomers}
                          getRow={(customer) => {
                              const tableCells = [
                                  <Avatar
                                      style={{border: "1px solid grey", width: "37px", height: "37px",cursor:"pointer"}}
                                      src={!_.isEmpty(customer.image) ? crud.baseUrl2 + "customer-profiles/" + customer.image : DefaultUserImage}
                                      onClick={() => setCustomerImage(customer)}
                                  />,
                                  customer.name,
                                  customer.mobile,
                                  customer.email,
                                  moment(customer.createdAt).format(crud.dateFormat),
                                  <span style={{
                                      background: `${customer.isActive ? "#5fdba7" : "#F96161"}`,
                                      padding: "5px 10px",
                                      borderRadius: "10px",
                                      fontSize: "12px",
                                      color: "#FFFFFF"
                                  }}>
                                      {customer.isActive ? "Active" : "Inactive"}</span>
                              ];
                              return {
                                  id: customer.id,
                                  cells: tableCells,
                                  actions: getData => <>
                                      {
                                          $user.hasPermission(CHANGE_CUSTOMER_STATUS) && <FormControlLabel
                                              className="mt-2 ml-1"
                                              control={
                                                  <Switch color="primary" size="small" checked={customer.isActive}
                                                          onChange={async e => {
                                                              const {checked} = e.target;
                                                              await crud.post("status/web/customer", {
                                                                  id: customer.id,
                                                              });
                                                              getData({clear: false});
                                                          }}
                                                  />
                                              }
                                          />
                                      }
                                  </>
                              }
                          }}
                          switchRow={(customer) => new Promise(resolve => {
                              setState(prev => ({
                                  ...prev,
                                  onSave: resolve
                              }));
                              setCustomers(customer);
                          })}
                          total={total}
            />
            <ImagePreview open={!!customerImage} close={() => setCustomerImage(null)}
                          imageUrl={customerImage?.image} path={"customer-profiles/"} title={"Customer Image"}
                          imageSize={"100%"}
                          defaultImage={DefaultUserImage}/>

            <Footer/>
        </>
    )
}

export default CustomerUser;

