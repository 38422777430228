import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {Typography} from "@material-ui/core";
import {crud} from "../../services/CrudFactory";

export default function DeleteDialog(props) {
    const {deleteId, refresh, open, close} = props;
    //================= delete api call============
    const deleteUser = async (id) => {
        await crud.post(`${props.url}?id=${id}`);
        refresh();
        close();
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // maxWidth={"sm"}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className="text-center">
                            <ErrorOutlineIcon style={{fontSize:"100px"}} />
                            <Typography variant="h3" className="mb-3 pl-5 pr-5">Are you sure?</Typography>
                            <Typography variant="body1">Do you want to delete this data?</Typography>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" size="small" onClick={() => deleteUser(deleteId.id)} style={{textTransform:"none"}}>
                        Yes
                    </Button>
                    <Button onClick={close} color="primary" style={{textTransform:"none"}} size="small" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
