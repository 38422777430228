import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#fff',
      background:"grey"
  },
}));

export default function SimpleBackdrop({set:set}) {
  const classes = useStyles();

  return (
      <div>
        <Backdrop className={classes.backdrop} open={set} style={{zIndex:"1301"}}>
          <CircularProgress color="inherit" /> <h3 className="mt-2">&nbsp;Please Wait</h3>
        </Backdrop>
      </div>
  );
}
