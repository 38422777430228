import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import { Box, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "50px",
		background: "#FFFFFF",
		position: "fixed",
		left: 0,
		bottom:0,
		zIndex:"99"
	},
	company__name:{
		[theme.breakpoints.down("sm")]: {
			display:"none !important"
		},
	}
}));

function Copyright() {
	const classes = useStyles();
	const d = new Date();
	return (
		<Typography variant="body2" className="d-flex justify-content-center align-items-center">
			Copyright © Designed &amp; Developed by{" "}&nbsp;
			<a href="http://technosters.com/" target="_blank" rel="noreferrer">
				Technosters <span className={classes.company__name}>Technologies</span>
			</a>{" "}&nbsp;{d.getFullYear()}
		</Typography>
	);
}



export default function Footer() {
	const classes = useStyles();
	return (
		<>
			<BottomNavigation className={classes.root} component={Box}>
					<Copyright />
			</BottomNavigation>
		</>
	);
}
