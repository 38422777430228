import React, {Fragment, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Divider,
    TextField,
    MenuItem,
    TextareaAutosize, Box,
} from "@material-ui/core";
import {crud} from "../services/CrudFactory";
import {PROFESSIONAL, CUSTOMER} from "../constant";

export default function NotificationDialog(props) {
    const [params, setParams] = useState({});
    const [saving, setSaving] = useState(false);

    const valueChange = (e) => {
        const {name, value} = e.target;
        setParams({...params, [name]: value});
    }

    const fileChangedHandler = (e) => {
        const name = e.target.name;
        const value = e.target.files[0];
        setParams({...params, [name]: value});
    };


    const notificationSubmit = async () => {
        try {
            setSaving(true);
            const formData = new FormData();
            Object.keys(params).forEach(key => formData.append(key, params[key]));
            await crud.post("create/web/notification", formData);
            cancel();
            props.getNotificationData();
        } finally {
            setSaving(false);
        }
    }

    const cancel = () => {
        props.close();
        setParams({});
    };

    return (
        <Fragment>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth={"sm"}
            >
                <form
                    method="post"
                    onSubmit={(e) => {
                        e.preventDefault();
                        notificationSubmit(e);
                    }}
                >
                    <DialogTitle>
                        <Typography variant="h5">Custom Notification</Typography>
                    </DialogTitle>
                    <Divider/>
                    <DialogContent className="mb-2 mt-1">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body2">Notification For</Typography>
                                <TextField
                                    id="standard-select-currency-native"
                                    select
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    select
                                    name="type"
                                    onChange={valueChange}
                                >
                                    <MenuItem value="any">Select</MenuItem>
                                    <MenuItem value={CUSTOMER}>Customer</MenuItem>
                                    <MenuItem value={PROFESSIONAL}>Professional</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">Notification Title</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder="Type Here"
                                    fullWidth
                                    name="title"
                                    onChange={valueChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body2" margin="normal">
                                    Notification Body
                                </Typography>
                                <TextareaAutosize
                                    maxRows={4}
                                    aria-label="maximum height"
                                    placeholder="Type Here"
                                    style={{
                                        width: "100%",
                                        height: "100px",
                                        borderRadius: "4px",
                                        padding: "5px",
                                    }}
                                    name="body"
                                    onChange={valueChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Notification Image (OPTIONAL)
                                </Typography>
                                <Box border={2} p={1} borderColor={"gray"}>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        variant="outlined"
                                        size="small"
                                        name="image"
                                        onChange={fileChangedHandler}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider/>

                    <DialogActions className="p-2">
                        <Button
                            variant="outlined"
                            onClick={() => cancel()}
                            color="primary"
                            size="small"
                            style={{textTransform: "none"}}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={saving}
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                            style={{textTransform: "none"}}
                        >
                            {
                                !params?.id ? (saving ? "Creating..." : "Create") : (saving ? "Updating..." : "Update")
                            }
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    );
}
