import React from "react";
import {TableRow, TableCell, Container} from "@material-ui/core";
import Skeleton from 'react-loading-skeleton';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

const SkeletonUse = ({limit, cellCount}) => {
    return (
        new Array(Number(limit)).fill(null).map(() => {
            return (
                <>  <TableRow>
                    {new Array(cellCount).fill(null).map(() => {
                        return (
                            <TableCell>
                                    <Skeleton count={1}/>
                            </TableCell>
                        )
                    })}
                </TableRow>
                </>
            )
        })
    )

}
export default SkeletonUse;

export const JobSkeletonUse = ({limit, cellCount}) => {
    return (
        new Array(Number(limit)).fill(null).map(() => {
            return (
                <>
                    <Container
                        maxWidth="xl"
                        style={{marginBottom: "65px", marginTop: "20px"}}
                    >
                        {new Array(cellCount).fill(null).map(() => {
                            return (
                                <Card style={{height:150}} className="mt-3">
                                    <CardContent>
                                        <Skeleton style={{height:110}} count={1}/>
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </Container>
                </>
            )
        })
    )
}
